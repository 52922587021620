import React from 'react';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";


const accordion= () => (
<div>


  <section className='container no-top no-bottom'>
    <div className='row'>

      
    <Card style={{ width: '30rem' }}>
  <Card.Img variant="top" src="./img/misc/MMX-coin.png" width={'30%'} />
  <Card.Body>
    <Card.Title>Card Title</Card.Title>
    <Card.Text>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary">Go somewhere</Button>
  </Card.Body>
</Card>
      </div>

      
      </section>
    </div>
  




);
export default accordion;