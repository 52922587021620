
import React from 'react';

const counters = () => (
    <div className='row'>
        <div class="col-md-3">
            <h2 style={{ fontSize: 20 }}>
                Blockchain
                <div className="small-border"></div>
            </h2>
            <p>Avalanche is the fastest smart contracts platform in the blockchain industry, as measured by finality.</p>
            <img src="./img/misc/avax.png" className="lazy img-fluid" alt="Avalanche" />
        </div>

        <div class="col-md-3">
            <h2 style={{ fontSize: 20 }}>
                Tokenomic
                <div className="small-border"></div>
            </h2>
            <p>The Metamerch's trade currency is MMX, an ARC-20 token in the Avalanche ecosystem.</p>
            <img src="./img/misc/mmx-coin.png" className="lazy img-fluid" alt="" />
        </div>

       
        <div class="col-md-3">
            <h2 style={{ fontSize: 20 }}>
                Technology
                <div className="small-border"></div>
            </h2>
            <p>Unreal Engine 5, will be used in various forthcoming games, including Metamerch's Metaverse environment.</p>
            <img src="./img/misc/unreal.png" className="lazy img-fluid" alt="" />
        </div>


        <div class="col-md-3">
            <h2 style={{ fontSize: 20 }}>
                File Storage
                <div className="small-border"></div>
            </h2>
            <p>Metamerch will utilise IPFS to store files, transactions, NFTs, and frontend webpages.</p>
            <img src="./img/misc/ipfs.png" className="lazy img-fluid" alt="" />
        </div>
    </div>
);
export default counters;