import React from 'react';


const Wallet = () => (
    <div className="row">

        <div className="col-lg-3 mb10">
            <span className="box-url">
                <img src="./img/partners/avax.png" width={'30%'} alt="Avax" className="mb10" />
            </span>
        </div>

        <div className="col-lg-3 mb10">
            <span className="box-url">
                <img src="./img/partners/unrealengine.png" width={'30%'} alt="" className="mb10" />
            </span>
        </div>

        <div className="col-lg-3 mb10">
            <span className="box-url">
                <img src="./img/partners/twinmotion.png" width={'50%'} alt="" className="mb10" />
            </span>
        </div>
       
       
        

        

    </div>
);
export default Wallet;