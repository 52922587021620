import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;



const NFTmain = () => (
  <section className='container-fluid p-md-0'>
    <div className='row'>
      <div className='col-md-5'>
        <img src="./img/misc/nft.png" className="lazy img-fluid" alt="Metamerch-NFT" />
      </div>

      <div className='col-md-6 centered'>
        <div>
          <div className="heading">
          <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
          <h6 className=""><span className="text-uppercase color">NFT Marketplace</span></h6>
          <h2 className="">Incoming Decorations,<span className='br'></span>Avatars & Store NFTs</h2>
          </Reveal>
          </div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
              <p className=" lead">
              Create Your NFT, Sell Avatars & Decorate Your Store
              </p>
              </Reveal>
          
        </div>
      </div>
    </div>
  
  </section >
);
export default NFTmain;
