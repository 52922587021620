import React from 'react';
import SliderMainParticle from '../components/SliderMainParticle';
import Footer from '../components/footer';
import Progress from '../pages/progressbar';
import CarouselNew from '../components/CarouselNew';
import Land from '../components/SliderMain';
import NFT from '../components/NFTmain';
import Particles from '../components/Particle';
import Counters from '../components/counters';
import { VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .navbar .mainside a{
    background: #FF343F;
    &:hover{
      box-shadow: 2px 2px 20px 0px #FF343F;
    }
  }
  .item-dropdown{
    .dropdown{
      a{
        &:hover{
          background: #FF343F;
        }
      }
    }
  }
  .btn-main{
    background: #FF343F;
    &:hover{
      box-shadow: 2px 2px 20px 0px #FF343F;
    }
  }
  p.lead{
    color: #a2a2a2;
  }
  .navbar .navbar-item .lines{
    border-bottom: 2px solid #FF343F;
  }
  .jumbotron.no-bg{
    height: 100vh;
    overflow: hidden;
    background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  #tsparticles{
    top: 0;
  }
  .text-uppercase.color{
    color: #FF343F;
  }
  .de_count h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
  .de_count h5{
    font-size: 14px;
    font-weight: 500;
  }
  h2 {
    font-size: 30px;
  }
  .box-url{
    text-align: center;
    h4{
      font-size: 16px;
    }
  }
  .de_countdown{
    border: solid 2px #FF343F;
  }
  .author_list_pp, .author_list_pp i, 
  .nft_coll_pp i, .feature-box.style-3 i, 
  footer.footer-light #form_subscribe #btn-subscribe i, 
  #scroll-to-top div{
    background: #FF343F;
  }
  footer.footer-light .subfooter .social-icons span i{
    background: #FF343F;
  }
  .author_list_pp:hover img{
    box-shadow: 0px 0px 0px 2px #FF343F;
  }
  .nft__item_action span{
    color: #FF343F;
  }
  .feature-box.style-3 i.wm{
    color: rgba(131,100,226, .1);
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #212428;
    }
    .item-dropdown .dropdown a{
      color: #212428 !important;
    }
  }
`;

const homethree = () => (
  <div>
    <GlobalStyles />
<Particles />
    <section className="jumbotron no-bg" style={{ backgroundImage: `url(${'./img/background/5.jpg'})` }}>
    
      <SliderMainParticle />
      
    </section>
  
    <section className='container no-bg'>
      <Land />
    </section>

    <section className='container no-bg no-top'>
      <NFT />
    </section>
    
    <section className='container no-bg no-top'>
      <Counters />
    </section>

    <section className='container no-bg no-top' id='roadmap'>
      <div className="small-border"></div>
      <h1 style={{ fontSize: 20 }}>Roadmap</h1>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#5e4ae3', color: '#212428' }}
          date="March 2022"
          iconStyle={{ background: '#5e4ae3', color: '#212428' }}
          icon={<img src='./img/misc/launch.png' width='100%' alt='Metamerch-Launch' />}
        >
          <h3 className="vertical-timeline-element-title">Project Launch</h3>
          <p>
            <ul>
              <li>The first round of land sales</li>
              <li>Growing community & start building Metamerch</li>
              <li>Reaching 100k active users & having full functional marketplace</li>
            </ul>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#947bd3', color: '#212428' }}
          date="2022 - 2023"
          iconStyle={{ background: '#947bd3', color: '#212428' }}
          icon={<img src='./img/misc/vricon.png' width='100%' alt='Metamerch-VR' />}
          
        >
          <h3 className="vertical-timeline-element-title">Phase 1</h3>
          <p>
            <ul>
              <li>Providing decoration and options for store owners</li>
              <li>Have at least 5k stores running with 3D items for sale</li>
              <li>Introducing VR to the platform</li>
            </ul>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#00c9ed', color: '#212428' }}
          date="2023 - 2024"
          iconStyle={{ background: '#00c9ed', color: '#212428' }}
          icon={<img src='./img/misc/social.png' width='100%' alt='Metamerch-Social' />}
        >
          <h3 className="vertical-timeline-element-title">Phase 2</h3>
          <p>
            <ul>
              <li>Reaching at least 300k active users</li>
              <li>Introduce activities and socialising to the platform</li>
              <li>Reaching 25k active stores</li>
            </ul>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#f26ca7', color: '#212428' }}
          date="2025"
          iconStyle={{ background: '#f26ca7', color: '#212428' }}
          icon={<img src='./img/misc/complete.png' width='100%' alt='Metamerch-Launch' />}
        >
          <h3 className="vertical-timeline-element-title">Phase 3</h3>
          <p>
            <ul>
              <li>Introducing AR to the platform</li>
              <li>Reaching 500k active users & being fully functional</li>
            </ul>
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </section>
    <section className='container no-top'>
      <center><h1 style={{ fontSize: 20 }}>Progress</h1></center>
      <div className="small-border"></div>
      <Progress />
    </section>
    <section className='container no-top' id='teamview'>
      <div className="small-border"></div>
      <h1 style={{ fontSize: 20 }}>The Team</h1>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='text-center'>
          </div>
        </div>
        <div className='col-lg-12'>
          <CarouselNew />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default homethree;