import React from 'react';
import { Link } from '@reach/router';

const footer = () => (
    <footer className="footer-light">
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Community</h5>
                        <ul>
                            <li><Link to="//discord.com/invite/GkezwjXPkw">Discord</Link></li>
                            
                            <li><Link to="//twitter.com/TheMetamerch">Twitter</Link></li>
                            
                            <li><Link to="//github.com/Metamerch">Github</Link></li>
                            
                            <li><Link to="//metamerch.medium.com">Medium</Link></li>
                            
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Partners</h5>
                        <ul>
                            <li><Link to="//www.Avax.network">Avalanche</Link></li>
                            <li><Link to="//www.unrealengine.com/">Unreal Engine</Link></li>
                            <li><Link to="//www.twinmotion.com/">Twinmotion</Link></li>
                            <li><Link to="//hapticmedia.com/">Hapticmedia</Link></li>
                            <li><Link to="//obsessar.com/">Obsess</Link></li>
                            <li><Link to="//www.invrsion.com/">Invrsion</Link></li>
                            <li><Link to="//cappasity.com/">Cappasity</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                    <div className="widget">
                        <h5>Resources</h5>
                        <ul>
                            <li><Link to="//docs.metamerch.finance">Metamerch Docs</Link></li>
                            <li><Link to="//docs.avax.network">Avalanche Docs</Link></li>
                            <li><Link to="//files.metamerch.finance/Metamerch-Whitepaper.pdf">Whitepaper</Link></li>
                            <li><Link to="//metamerch.medium.com">Medium</Link></li>
                            <li><Link to="//docs.unrealengine.com/5.0/en-US/">UE5 Docs</Link></li>
                            <li><Link to="//blog.metamerch.finance">Blog</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <p>Signup for our newsletter to get the latest news in your inbox</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> 
                                    <Link to="" id="btn-subscribe">
                                      <i className="arrow_right bg-color-secondary"></i>
                                    </Link>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                    </div>
                </div>
            </div>
        <div className="subfooter">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex">
                            <div className="de-flex-col">
                                <span onClick={() => window.open("", "_self")}>
                                    <span className="copy">&copy; Copyright 2022 - All Rights Reserved By Metamerch Finance</span>
                                </span>
                            </div>
                            <div className="de-flex-col">
                                <div className="social-icons">
                                    <span onClick={() => window.open("//twitter.com/TheMetamerch", "_self")}><i className="fa fa-twitter fa-lg"></i></span>
                                    <span onClick={() => window.open("//metamerch.medium.com", "_self")}><i className="fa fa-medium fa-lg"></i></span>
                                
                                    <span onClick={() => window.open("//github.com/Metamerch", "_self")}><i className="fa fa-github fa-lg"></i></span>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);
export default footer;