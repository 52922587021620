import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;



class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {

  dummyData = [{
        deadline:"December, 30, 2021",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/items/linkedin.png",
        previewImg: "./img/items/linkedin.png",
        title: "Pinky Ocean",
        
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/items/linkedin.png",
        previewImg: "./img/items/static-2.jpg",
        title: "Deep Sea Phantasy",
        price: "0.06 ETH",
        bid: "1/22",
        likes: 80
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-11.jpg",
        previewImg: "./img/items/static-3.jpg",
        title: "Rainbow Style",
        price: "0.05 ETH",
        bid: "1/11",
        likes: 97
    },
    {
        deadline:"January, 1, 2022",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-12.jpg",
        previewImg: "./img/items/static-4.jpg",
        title: "Two Tigers",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-9.jpg",
        previewImg: "./img/items/anim-4.webp",
        title: "The Truth",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"January, 15, 2022",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/items/linkedin.png",
        previewImg: "./img/items/george.jpg",
        title: "Running Puppets",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-3.jpg",
        previewImg: "./img/items/anim-1.webp",
        title: "USA Wordmation",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-4.jpg",
        previewImg: "./img/items/anim-5.webp",
        title: "Loop Donut",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"January, 3, 2022",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-6.jpg",
        previewImg: "./img/items/anim-3.webp",
        title: "Lady Copter",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-7.jpg",
        previewImg: "./img/items/static-6.jpg",
        title: "Purple Planet",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-6.jpg",
        previewImg: "./img/items/anim-6.webp",
        title: "Oh Yeah!",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"January, 10, 2022",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-6.jpg",
        previewImg: "./img/items/anim-7.webp",
        title: "This is Our Story",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/author/author-9.jpg",
        previewImg: "./img/items/static-6.jpg",
        title: "Pixel World",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    },
    {
        deadline:"January, 10, 2022",
        authorLink: "#",
        nftLink: "#",
        bidLink: "#",
        authorImg: "./img/items/linkedin.png",
        previewImg: "./img/items/anim-8.webp",
        title: "I Believe I Can Fly",
        price: "0.08 ETH",
        bid: "1/20",
        likes: 50
    }]

  constructor(props) {
      super(props);
      this.state = { deadline: "January, 10, 2022", deadline1: "February, 10, 2022", deadline2: "February, 1, 2022" };
    }

  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    };
    return (
        <div className='nft'>
          <Slider {...settings}>
            

          <CustomSlide className='itm' index={1}>
            <div className="d-item">
                <div className="nft__item">
                    <div className="author_list_pp">
                        <span onClick={()=> window.open("//www.linkedin.com/in/hamish-brown/", "_self")}>                                    
                            <img className="lazy" src="./img/items/linkedin.png" alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap">
                      <Outer>
                        <span>
                            <img src="./img/items/hamish.png" className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open("//www.linkedin.com/in/hamish-brown/", "_self")}>
                           <center><h4>Hamish Brown</h4></center> 
                        </span>
                        <div className="nft__item_price">
                           <center>CEO & Director</center>
                        </div>                                 
                    </div> 
                </div>
            </div>    

            </CustomSlide>


            <CustomSlide className='itm' index={2}>
            <div className="d-item">
                <div className="nft__item">
                    <div className="author_list_pp">
                        <span onClick={()=> window.open("//www.linkedin.com/in/mahan-bagheri-32068b231/", "_self")}>                                    
                            <img className="lazy" src="./img/items/linkedin.png" alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap">
                      <Outer>
                        <span>
                            <img src="./img/items/mahan.png" className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open("//www.linkedin.com/in/mahan-bagheri-32068b231/", "_self")}>
                           <center><h4>Mahan Bagheri</h4></center> 
                        </span>
                        <div className="nft__item_price">
                           <center>CTO & Director</center>
                        </div>                                 
                    </div> 
                </div>
            </div>    

            </CustomSlide>



            <CustomSlide className='itm' index={3}>
            <div className="d-item">
                <div className="nft__item">
                    <div className="author_list_pp">
                        <span onClick={()=> window.open("//www.linkedin.com/in/aryanbagheri/", "_self")}>                                    
                            <img className="lazy" src="./img/items/linkedin.png" alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap">
                      <Outer>
                        <span>
                            <img src="./img/items/aryan.png" className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open("//www.linkedin.com/in/aryanbagheri/", "_self")}>
                           <center><h4>Aryan Bagheri</h4></center> 
                        </span>
                        <div className="nft__item_price">
                           <center>COO & Director</center>
                        </div>                                 
                    </div> 
                </div>
            </div>    

            </CustomSlide>


            <CustomSlide className='itm' index={4}>
            <div className="d-item">
                <div className="nft__item">
                    <div className="author_list_pp">
                        <span onClick={()=> window.open("//www.linkedin.com/in/naveed-anwar-1a54b2189/", "_self")}>                                    
                            <img className="lazy" src="./img/items/linkedin.png" alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap">
                      <Outer>
                        <span>
                            <img src="./img/items/naveed.png" className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open("//www.linkedin.com/in/naveed-anwar-1a54b2189/", "_self")}>
                           <center><h4>Naveed Anwar</h4></center> 
                        </span>
                        <div className="nft__item_price">
                           <center>Financial Advisor</center>
                        </div>                                 
                    </div> 
                </div>
            </div>    

            </CustomSlide>

            <CustomSlide className='itm' index={5}>
            <div className="d-item">
                <div className="nft__item">
                    <div className="author_list_pp">
                        <span onClick={()=> window.open("//www.linkedin.com/in/moein-bagheri-95a2b7174/", "_self")}>                                    
                            <img className="lazy" src="./img/items/linkedin.png" alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap">
                      <Outer>
                        <span>
                            <img src="./img/items/moein.png" className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open("//www.linkedin.com/in/moein-bagheri-95a2b7174/", "_self")}>
                           <center><h4>Moein Bagheri</h4></center> 
                        </span>
                        <div className="nft__item_price">
                           <center>3D Engineer & Director</center>
                        </div>                                 
                    </div> 
                </div>
            </div>    

            </CustomSlide>


            
          </Slider>
        </div>
    );
  }
}
