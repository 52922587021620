import React from 'react';

const progress = () => (
  <div>
    <section className="container no-top no-bottom">
      <div className="row">
        <div className="col-md-4">
          <div className="skill-bar style-2">
            <h6>Stage 1</h6>
            <div className="de-progress">
              <div className="progress-bar" style={{ width: `40%` }}>
              </div>
            </div>
          </div>
          <div className="skill-bar style-2">
            <h6>Community Growth</h6>
            <div className="de-progress">
              <div className="progress-bar" style={{ width: `20%` }}>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="skill-bar style-2">
            <h6>NFT Creation</h6>
            <div className="de-progress">
              <div className="progress-bar" style={{ width: `15%` }}>
              </div>
            </div>
          </div>
          <div className="skill-bar style-2">
            <h6>Virtual Worlds</h6>
            <div className="de-progress">
              <div className="progress-bar" style={{ width: `10%` }}>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="skill-bar style-2">
            <h6>Virtual Stores</h6>
            <div className="de-progress">
              <div className="progress-bar" style={{ width: `5%` }}>
              </div>
            </div>
          </div>
          <div className="skill-bar style-2">
            <h6>Collectibles</h6>
            <div className="de-progress">
              <div className="progress-bar" style={{ width: `25%` }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);
export default progress;