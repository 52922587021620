import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Link } from '@reach/router';
import { scroller } from "react-scroll";


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const scrollToSection = (id) => {
  scroller.scrollTo(id, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

function Header() {
    
  
  
  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  return (
    <header id="myHeader" className='navbar white'>
      <div className='container'>
        <div className='row w-100-nav'>
          <div className='logo px-0'>
            <div className='navbar-title navbar-item'>
              <NavLink to="/">
                <img
                  src="./img/logo-new.png"
                  className="img-fluid d-none"
                  alt="Metamerch" />
                  
              </NavLink>
            </div>
          </div>

          <div className='search'>

          <div className=' navbar-item'>

          <NavLink to="//twitter.com/TheMetamerch">
            <img src="./img/misc/twitter-icon.png" alt="Metamerch-Twitter" />
             
          
             
           
          
           </NavLink>
           &nbsp;&nbsp;&nbsp;
           <NavLink to="//discord.com/invite/GkezwjXPkw">
            <img src="./img/misc/discord-icon.png" alt="Metamerch-Discord" />
             
           </NavLink>
         



              </div>               
                                   
          </div>


          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu &&
                <div className='menu'>
                  <div className='navbar-item'>
                    <NavLink to='/' onClick={() => btn_icon(!showmenu)}>
                      Home
                      <span className='lines'></span>
                    </NavLink>
                  </div>
                  <div className='navbar-item'>
                    <NavLink to='//studio.metamerch.finance' onClick={() => btn_icon(!showmenu)}>
                      Studio
                      <span className='lines'></span>
                    </NavLink>
                  </div>
                  <div className='navbar-item' onClick={(e) => { e.preventDefault(); scrollToSection("teamview"); }}>
                    <NavLink to='/team'>
                      Team
                      <span className='lines'></span>
                    </NavLink>
                  </div>
                  <div className='navbar-item'>
                    <NavLink to= '//files.metamerch.finance/Metamerch-Whitepaper.pdf'>
                      Whitepaper
                      <span className='lines'></span>
                    </NavLink>
                  </div>
                  <div className='navbar-item' onClick={(e) => { e.preventDefault(); scrollToSection("roadmap"); }}>
                    <NavLink to='/roadmap'>
                      RoadMap
                      <span className='lines'></span>
                    </NavLink>
                  </div>
                  <div className='navbar-item'>
                    <NavLink to='//docs.metamerch.finance/'>
                      Docs
                      <span className='lines'></span>
                    </NavLink>
                    <div className='navbar-item mainside'>
                    <NavLink to="//app.metamerch.finance/" className="btn-main">Launch App</NavLink>
                  </div>
                </div>
                  </div>
                  
                
                }
            </Breakpoint>
            <Breakpoint xl>
              <div className='menu'>
                <div className='navbar-item'>
                  <NavLink to='/'>
                    Home
                    <span className='lines'></span>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='//studio.metamerch.finance'>
                    Studio
                    <span className='lines'></span>
                  </NavLink>
                </div>
                <div className='navbar-item' onClick={(e) => { e.preventDefault(); scrollToSection("teamview"); }}>
                  <NavLink to='/team'>
                    Team
                    <span className='lines'></span>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to= '//files.metamerch.finance/Metamerch-Whitepaper.pdf'>
                    Whitepaper
                    <span className='lines'></span>
                  </NavLink>
                </div>
                <div className='navbar-item' onClick={(e) => { e.preventDefault(); scrollToSection("roadmap"); }}>
                  <NavLink to='/roadmap'>
                    Roadmap
                    <span className='lines'></span>
                  </NavLink>
                </div>
                <div className='navbar-item'>
                  <NavLink to='//docs.metamerch.finance'>
                    Docs
                    <span className='lines'></span>
                  </NavLink>
                </div>
                <div className='mainside'>
            <div className='navbar-item mainside'>
              <NavLink to='//app.metamerch.finance' className="btn-main">Launch App</NavLink>
            </div>
          </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>
          
        </div>
        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
}
export default Header;